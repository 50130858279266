





.App {
  font-family: sans-serif;
  text-align: center;
}

body {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  /*  background-color: #F6FBFC; */
  font-size: 14px;
}

#calendar {
  max-width: 100%;
  margin: -10px;
  height: 800px;
}

.fc-resourceTimelineDay-view > .fc-content {
  white-space: pre;
}

.fc-event-title-container {
  color: blue;
  display: inline-block;
  /* need this to be sticky cross-browser */
  vertical-align: top;
  /* for not messing up line-height */

  text-align: center;
}
.fc-fri { color:blue; }
.fc-sat { color:red;  }
.fc-day-grid-event > .fc-content {
  white-space: nowrap;
  overflow: scroll;
}

.fc-timeline-events {
}

span.align-right {
  text-align: right;
  margin-right: 2%;
  display: block;
  margin-top: -60px;
}

@media screen and (max-width: 1000px) {
  .container {
    width: 100%;
  }
  #calendar {
    height: 2000px;
  }
}

.right {
  float: right;
}

.fc-header-toolbar {
  background: #88b783;
}

.button {
  background-color: #4caf50;
}

.fc-toolbar h2 {
  display: inline;
}

.fc-timeline-event {
  padding: 0 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid #000000;
}

.changeToBlack{
  color:black;
}
.newline {
  width: 100%;
}

.fc-scroller {
  /*for giving outlining*/
  border: solid;
  
}

.hoverbox {
  background-color: grey;
  color: white;
  border: 2px solid black;
  margin: 20px;
  padding: 20px;
  width: 50%;
  opacity: 1;
}

.fc-timeline-slot-cushion {
  font-size: 15px;
}
.fc-datagrid-cell-main {
  /* font-size: 25px; */
}

.fc-event {
  height: auto !important;
  text-align: left;
  white-space: normal !important;
}

.fc-datagrid-cell-frame {
  /* background-color: #BEBEBE; */
    /* background-color: white; */
  color: white;
    margin-bottom: 20px !important
}
.fc-timeline-slot-frame {
  /* background-color: #BEBEBE; */
  /* background-color: white; */
  /* color: black; */
}

.fc-datagrid-cell-cushion {
}
.fc-timeline-events {
}
.fc-datagrid-cell-main {
  color: black;
}

input[type="date"] {
  width: 50%;
  padding: 7px 7px;

  box-sizing: border-box;
}
select {
  width: 25%;
  padding: 7px 7px;
  box-sizing: border-box;
}

input[type="submit"] {
  width: 20%;
  padding: 7px 7px;

  box-sizing: border-box;
}
.fc-timeline-now-indicator-container{
  color:blue;
}
.fc-timeline-now-indicator-arrow{
  color:blue
}

/*  GIVING MARGIN  AT THE BOTTOM SO THAT I CAN REGISTER EVENT*/
.fc-timeline-event-harness{
  
  /* padding-bottom: 10px; */
}

/* Hide characters inside elements with class .fc-timeline-header-row-chrono */

/* .fc-timeline-header-row-chrono {
  color: transparent;
  display: none
} */

/* Apply styles to all but the first row in .fc-timeline-lane-frame */
.fc-timeline-lane-frame:not(:first-child) {
  margin-bottom: 25px;
}

/* Apply styles to all but the first row in .fc-datagrid-cell-frame */
.fc-datagrid-cell-frame:not(:first-child) {
  margin-bottom: 25px;
}

.fc-datagrid-body{
  /* background-color: #BEBEBE; */
}

/* Apply red background to every even .fc-timeline-slot */
.fc-timeline-slot:nth-child(even) {
    /* background-color: red;  */
}

.fc-daygrid-event{
 background-color: inherit; 
}
.fc-day-sat{
  color:blue !important
}
.fc-day-sun{
  color:red !important
}

.fc-daygrid-day-events .fc-daygrid-event-harness:last-child {
  padding-bottom: 10px; /* Adjust the margin as needed */
  background-color: red
}
.fc-daygrid-day-bottom{
  /* margin-top: 40px !important */
  /* background-color:var(--fc-more-link-bg-color, #d0d0d0); */
}
.fc-timeline-lane-frame {
  margin-bottom: 40px !important
}

.fc-datagrid-cell-frame {
  color: white;
  margin-bottom: 40px !important
}
.fc-scrollgrid-section-header{

}
.fc-scroller-harness{
  background-color: white;
  margin-bottom: -50px !important;
  /* height:1000px!important; */
}
.fc-slot-today{
  background-color: var(--fc-today-bg-color, rgba(255, 220, 40, 0.15))
}

.fc-daygrid-day-bg{
    margin-top: 40px !important
}

.fc-timeline-more-link-inner,.fc-more-link{
  /* background-color: white !important */
}
.fc-daygrid-more-link{
  display: block; /* Make it a block-level element */
  width: 100%;
  background-color: #d0d0d0
}
.fc-scrollgrid ,. fc-scrollgrid-liquid {
  /* border: 2px solid black !important;  */
}

/* CSS to change the border width of the calendar */
.fc-border-color{
  border: 1px solid red !important; /* Change the border width and color as needed */
  /* Additional styles for the calendar container */

  /* Other styles like background color, margin, padding, etc. */
}

.fc-scroller{
  border-top:1px solid black;
  border-left: 2px solid black !important; 
  border-right: 2px solid black !important; 
  border-bottom: 0px solid black !important; 
}

  .fc-day-today {
  background-color: var(--fc-today-bg-color, rgba(255, 220, 40, 0.15))!important;
}


.mantine-DatePicker-month tbody tr td:first-child button {
    color: red !important;
}

/* Style the last td of each row (Saturday) */
.mantine-DatePicker-month tbody tr td:last-child button {
    color: blue!important;
}

.horizontal-line {
margin-left: 1px;
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 0px;
  background-color: black;
}

.space {
   justify-content: space-between !important; 
  /* margin-top: 40px; */
  font-weight: bold;
  flex: 1;
  height: 45px;
  background-color: transparent;
  text-align: center;
  border-top: 2px solid black; 
  border-right: 1px solid  lightgrey; /* Add a black solid border to all four sides of each space */
  padding :10px;

  
}

